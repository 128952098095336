import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { menuOutline, refresh } from 'ionicons/icons';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NodeBuilderFlags } from 'typescript';
import PostListItem from '../../../components/PostListItem/PostListItem';
import { iPost } from '../../../data/posts';
import { getToken } from '../../../utils/Common';
import { Virtuoso } from 'react-virtuoso';

interface PostProps {
   feed: string;
   type: string;
   username?: string;
   id?: string;
}

function Posts(){


   const [loading, setLoading] = useState<boolean>(true);
   const [posts, setData] = useState<iPost[]>([]);
   const [allPosts, setAllPosts] = useState<iPost[]>([]);
   const perPage = 10;
   const [lastPosition, setLastPosition] = useState(perPage);


   async function fetchData() {
    const url: string = `https://nightoff.org/tempapi/posts/public?token=${getToken()}&feed=public`;
    const res: Response = await fetch(url);
    res
        .json()
        .then(async (res) => {
          if (res && res.slice() && res.slice().length > 0) {
            const newData = await res;
            setData(newData);
            console.log(newData);
            setAllPosts(newData.slice(0, perPage));
            setLoading(res.slice().length < 10);
          } else {
            setLoading(true);
          }
        })
        .catch(err => console.error(err));
  }

  useIonViewWillEnter(async () => {
    await fetchData();
  });

  //load more posts
    const loadPosts = () => {
      setLoading(true);
        // we subtract with perPage to account from the initial amount loaded that is not accounted for here
        if (allPosts.length >= (posts.length)) {
          console.log('total posts is less than or equal to current amount of loaded posts')
          console.log('Total: ' + (allPosts.length) + '/' + posts.length);   
          setLoading(false);
          return;
        }           
      setTimeout(() => {
        setAllPosts((prev) => [
          ...prev,
          ...posts.slice(lastPosition, lastPosition + perPage),
        ]);
        console.log('Added ' + perPage + ' more posts to existing ' + lastPosition + ' loaded posts for a total of ' + (allPosts.length + perPage) + '/' + posts.length);   
      }, 100);
      setLastPosition(lastPosition + perPage);
      setLoading(false);
    };
    
    const loadMore = useCallback(() => {
      return setTimeout(() => {
        loadPosts();
      }, 100)
    }, [allPosts])
  
  
    useEffect(() => {
      setLoading(true);
      console.log(loading);
      const timeout = loadMore()
      return () => clearTimeout(timeout)
    }, [])


//load posts with infinite scrolling
function RenderPosts(){
return(
  <IonList>
      {allPosts && allPosts.length > 0 && !loading && allPosts < posts ? allPosts.map((post:iPost)=>
<PostListItem key={post.PostId} post={post} />)
: <div>
  fawiuefew
  </div>}
      </IonList>
)
}

const Footer = () => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      Loading...
    </div>
  )
}

const EndFooter = () => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      Loading...
    </div>
  )
}


const postRows = () => {

  if (!Array.isArray(allPosts) || !allPosts.length) return <PostListItem key={3} />
  return (
    <Virtuoso
    className="ion-content-scroll-host"
    style={{ height: '90%' }}
    data={allPosts}
    overscan={50}
    endReached={loadMore}
    totalCount={posts.length}
    itemContent={(index, post:iPost) => {
      return (
<PostListItem key={index} post={post} />
      );
    }}
    components={{
      Footer: () => {
        return (
          <div
            style={{
              padding: (lastPosition <= posts.length ? '1rem' : '2rem'),
              textAlign: 'center',
            }}
          >
           {allPosts.length >= (posts.length) ? "end reached" : "Loading..."}
          </div>
        )
      },
    }}
  />
  )
}



  return (
    <IonPage id="home-page">
      <IonHeader translucent={true}>
        <IonToolbar >
        <IonButtons slot="start">
              <IonButton color="transparent" size="small">
                <IonIcon icon={menuOutline}></IonIcon>
              </IonButton>
            </IonButtons>          
          <IonTitle>Timeline</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen role="feed" scrollY={false}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Timeline
            </IonTitle>
          </IonToolbar>
        </IonHeader>
    {postRows()}
      </IonContent>
    </IonPage>
    );
}

export default Posts;