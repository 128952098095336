import {
  InputChangeEventDetail,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { eyeOff, eye, at, chevronBack } from 'ionicons/icons'
import { RefObject, useEffect, useRef, useState } from 'react'
import { animationBuilder, pageTransition } from '../../../App'
import { useHistory } from 'react-router';
import styles from './Onboarding.module.css' // Import css modules stylesheet as styles
import AuthProvider from '../../../AuthProvider/AuthProvider'
import React from 'react'
import { hideTabs } from '../../../routes/LoggedIn';
import { IonInputCustomEvent } from '@ionic/core';

const Onboarding: React.FC = () => {
  /*
    useState<string | undefined | null>("");
    because event.detail.value might be a string or it can be null or undefined
  */

  const [userName, setUserName] = useState<string | undefined | null>('');
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(false);
  const { isUsernameTaken, changeUsername, logOut } = React.useContext(AuthProvider);
  const [logOutLoading, setLogOutloading] = useState<boolean>(false);


  
  useEffect(() => {
    let checkUsername = isUsernameTaken(userName);
    checkUsername.then((taken:boolean) => {          
      if(taken && userName!.length > 3){
        console.log("Username exists");
        setUsernameAvailable(false);
      } else if(userName!.length>3) {
        console.log("Username does not exist");
        setUsernameAvailable(true);
      }
      });
}, [userName]);

function handleLogout(){

    if(logOutLoading){
      return;
    }
    setLogOutloading(true);
    let logout = logOut();
    logout.then((result:boolean) => {          
      if(result){
        setLogOutloading(false);
        console.log("Successfully logged out");
        history.replace("/signin");      
      } else if (result === false) {
        setLogOutloading(false);
        console.log("Could not successfully logout!");
        return;
      }
    })
  }

const { doesUsernameExist } = React.useContext(AuthProvider);
useEffect(() => {
    let username = doesUsernameExist();
    username.then((exists:boolean) => {           
    if(exists){
      console.log("Username exists");
      history.replace("/home");      
    } else {
      return;
    }
    })
}, []);

const history = useHistory();


const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

  event.preventDefault();
  event.stopPropagation();
  console.log({ userName })
  
  if(userName){
    let result = await changeUsername({ username: userName});
    if(result){
      console.log(result)
      console.log('Successfully set username');
      history.replace("/home");
    } else {
      console.log('Could not set username')
    }
  } else {
    console.log('Either username is taken or does not meet our expected character limit');
  }
}

  hideTabs();


  const userNameComponent = () => {
    return (
      <IonContent>
        <p className='ion-text-center text-white font-bold m-8 pt-10 text-5xl'>Make it yours</p>
        <p className='ion-text-center text-white font-medium m-6 mt-5 text-md'>
          This one is mandatory. Pick a username. We&apos;ll leave the creativity to you.
        </p>
        <div className='ion-margin-horizontal'></div>
        <form
           onSubmit={(e) => {
            handleSubmit(e)
          }}> 
        <div className="ion-padding">
        <IonItem className='items-end'>
          <IonLabel position='floating'>Username</IonLabel>
          <IonIcon
            slot='start'
            className='cursor-pointer select-none animate__animated animate__fadeIn focus:select-none hover:text-gray-200 focus:text-gray-600 focus:text-opacity-50'
            icon={at}
          />
          <IonInput
            id='userNameEnter'
            class='passwordEnter'
            clearOnEdit={true}
            inputmode='text'
            enterkeyhint='go'
            type='text'
            value={userName}
            onIonChange={function(e) {
              setUserName(e.detail.value);
          }}
            required
          ></IonInput>
        </IonItem>
        <p className='ion-text-end font-small m-6 text-sm text-white'>{userName !== "" ? usernameAvailable ? userName!.length > 3 ? 'Available' : 'Must be more than 3 characters!' : 'Taken'  : 'Empty'}</p>
        </div>
        <div className='flex justify-center mt-6'>
        <IonButton
              expand='block'
                size='large'
                type="submit"
                disabled={userName !== "" ? usernameAvailable ? userName!.length > 3 ? false : true : true  : true}
                className='flex mt-24 font-bold transition cursor-pointer select-none focus:select-none hover:text-gray-100 focus:text-opacity-50'
                color='white'
                fill='clear'
              >
                Continue
              </IonButton>
        </div></form>
      </IonContent>
    )
  }

  return (
    <IonPage className={`${styles.bg}`}>
      <IonHeader>
        <IonToolbar  color="primary">
          <IonButtons slot='start'>
            <IonButton color='white' onClick={()=>handleLogout()} disabled={logOutLoading}><IonIcon icon={chevronBack}></IonIcon>{logOutLoading ? 'Logging Out...' : 'Logout'}</IonButton>
          </IonButtons>
          <IonTitle color='white'>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>
        {userNameComponent()}
    </IonPage>
  )
}

export default Onboarding
