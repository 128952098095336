import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Recovery from '../pages/NotLoggedIn/Recovery/Recovery';
import Root from '../pages/NotLoggedIn/Root/Root';
import SignIn from '../pages/NotLoggedIn/SignIn/Signin';
import SignUp from '../pages/NotLoggedIn/SignUp/SignUp';



const NotLoggedIn: React.FC<RouteComponentProps> = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/" component={Root} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/recovery" component={Recovery} />
    </IonRouterOutlet>
  );
};

export default NotLoggedIn;