import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import API from '../utils/API';
import { getToken, getUser, removeLoginSession } from '../utils/Common';

import { setLoginDetails, setUserToken } from '../utils/Login';
import Logouta from '../utils/Logout';


let auth = {};

//create context
export const Context = React.createContext<any>(undefined);

/**
 * Authentication/Authorization context for managing
 * authenticating/ed and authorizing/ed users
 */
export const AuthProvider: React.FC = ({ children }) => {

    const [authValues, setAuthValues] = React.useState({
        authenticated: false,
        uid: null,
        username: null,
    });

    const [registerValues, setRegisterValues] = React.useState({
        registered: false,
        email: null,
    });

    // handle button click of login form
    const login = ({ user, password }: { user: string; password: string }) => {
        return new Promise((resolve) => {
            axios.post('https://nightoff.org/tempapi/auth', { username: user, password: password }).then(response => {
                const details = JSON.parse(JSON.stringify(response.data));
                setUserToken(details['Token']);
                //setLoginDetails(JSON.stringify(response.data));
                setAuthValues({
                    authenticated: true,
                    uid: details['Uid'],
                    username: details['username']
                });                
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
        });
    };

    // handle account verification
    const doesUsernameExist = () => {
        return new Promise((resolve) => {
            const token = getToken();
            if (!token) {
                return;
            }
            API.get(`user?token=${token}&information=username`).then(() => {
                    resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    };

    // handle account verification
    const isUsernameTaken = (username:string) => {
        return new Promise((resolve) => {
            API.get(`user?username=${username}`).then(() => {
                    resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    };    

    // handle button click of signup form

    const register = ({ email, password }: { email: string; password: string }) => {
        return new Promise((resolve) => {
            axios.post('https://nightoff.org/tempapi/register', { email: email, password: password }).then(response => {
                let registeredEmail:any = email;
            setRegisterValues({
                    registered: true,
                    email: registeredEmail
                });
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
        });
    };

    const changeUsername = ({ username }: { username: string }) => {
        const token = getToken();
        if (!token) {
            return;
        }           
        return new Promise((resolve) => {
            axios.post('https://nightoff.org/tempapi/changeusername', { username: username, token: token }).then(response => {
            //update username value
            const details = JSON.parse(JSON.stringify(response.data));
            authValues.username = details['Username'];
                resolve(true);
            }).catch(error => {
                resolve(false);
            });
        });
    };  


    // handle account verification
    const verify = () => {
        return new Promise((resolve) => {
            const token = getToken();
            if (!token) {
                return;
            }
            API.get(`verifyToken?token=${token}`).then((response: { data: JSON; }) => {
                const details = JSON.parse(JSON.stringify(response.data));
                setUserToken(details['Token']);
                //setLoginDetails(JSON.stringify(response.data));
                setAuthValues({
                    authenticated: true,
                    uid: details['Uid'],
                    username: details['username']
                });
                resolve(true);
            }).catch(() => {
                setAuthValues({
                    authenticated: false,
                    uid: null,
                    username: null,
                });
                resolve(false);
            });
        });
    };

    const history = useHistory();

    // handle button click of logout form
    const logOut = async () => {
        const isLoginSessionRemoved = await removeLoginSession();
        console.log("Result from removeLoginSession in common.tsx from logOut in AuthProvider.tsx returned: " + isLoginSessionRemoved);
        if(isLoginSessionRemoved){
            console.log('Defaulting AuthValues in AuthProvider since isLoginSessionRemoved returned success...')
            console.log("Current status of authValues.authenticated: " +authValues.authenticated);
            if (!authValues.authenticated) {
                console.log('logOut() from authProvider.tsx returned true. Fully completed operation successfully.')
                return Promise.resolve(true);
              } else {
                console.log('Error: authValues.authenticated returned true instead of false. Could not finish entire operation. Attempting to set it to false...');                
                setAuthValues({
                    authenticated: false,
                    uid: null,
                    username: null,
                });
                console.log('logOut() from authProvider.tsx reset authValues to default. Fully completed operation successfully.');
                return Promise.resolve(true);
              }
        } else if (!isLoginSessionRemoved) {
            console.log('Error: isLoginSessionRemoved returned false, could not reset AuthValues and finish logOut Operation.');
         return Promise.resolve(false);
        } else {
            console.log('Error: isLoginSessionRemoved is neither true or false, please contact a developer.');
         return Promise.resolve(false);
        }
    };

    let state = {
        authValues,
        registerValues,
        login,
        logOut,
        register,
        doesUsernameExist,
        isUsernameTaken,
        changeUsername,
        verify,
    };

    return <Context.Provider value={state}>{children}</Context.Provider>
};

export default Context;