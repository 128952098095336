import {
  IonIcon,
  IonItem,
  IonLabel,
  IonNote
  } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { iPost } from '../../data/posts';
import './PostListItem.css';


interface PostListItemProps {
  post?: iPost;
}

const postListItem: React.FC<PostListItemProps> = ({ post }) => {


  if (!post) return <div></div>


  return (
    <>
  <IonItem className="border-b-2 border-gray-100" routerLink={`/post/${post.PostId}`} detail={false} lines="none" role="article">
        <IonIcon icon={personCircle} slot="start" color="primary" className='avatar'></IonIcon>
        <IonLabel className="ion-text-wrap">
          <h2>
          {post.PostedBy}
            <span className="date">
              <IonNote>{post.PostedOn}</IonNote>
            </span>
          </h2>
          <p>
          </p>
          <h3>
          {post.PostBody}
          </h3>
        </IonLabel>
      </IonItem></>

  );
};

export default postListItem;
