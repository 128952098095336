import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonViewDidEnter } from '@ionic/react';
import { getAllByPlaceholderText } from '@testing-library/react';
import { addCircle, addCircleOutline, home, homeOutline, informationCircle, notifications, notificationsOutline, person, personOutline, search, searchOutline, settings, settingsOutline } from 'ionicons/icons';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import AuthProvider from '../AuthProvider/AuthProvider';
import Timeline from '../pages/LoggedIn/Timeline/Timeline';
import ViewPost from '../pages/LoggedIn/ViewPost/ViewPost';
import { getToken } from '../utils/Common';
import styles from './LoggedIn.module.css'; // Import css modules stylesheet as styles
import { useHistory } from 'react-router';
import Onboarding from '../pages/LoggedIn/Onboarding/Onboarding';
import Settings from '../pages/LoggedIn/Settings/Settings';
import ChangeUsername from '../pages/LoggedIn/Settings/ChangeUsername/ChangeUsername';
import Feedback from '../pages/LoggedIn/Settings/Feedback/Feedback';
import Contact from '../pages/LoggedIn/Settings/ContactUs/Contact';


//functions used in page components to hide or show tabs *mandatory//each
export function hideTabs() {
    const tabsEl = document.querySelector('ion-tab-bar');
    if (tabsEl) {
        tabsEl.hidden = true;
    }
}

export function showTabs() {
    const tabsEl = document.querySelector('ion-tab-bar');
    if (tabsEl) {
        tabsEl.hidden = false;
    }
}

//necessary since translucent tab bar is absolute
export function addPaddingToContent() {
    setTimeout(() => {
        const container = document.querySelector('ion-content');
        if (container) {
            container.style.setProperty('--padding-bottom', '44px');
        }
    });
}



const LoggedIn: React.FC<RouteComponentProps> = ({ match }) => {
    const history = useHistory();
    const { doesUsernameExist } = React.useContext(AuthProvider);
    useEffect(() => {
        let username = doesUsernameExist();
        username.then((exists:boolean) => {          
        if(exists){
          console.log("Username exists");
        } else {
          console.log("Username does not exist");
          history.replace("/onboarding");
        }
        })
    }, [doesUsernameExist]);

    const tabs = [
        {
            name: "Home",
            url: "/home",
            activeIcon: home,
            icon: homeOutline,
            component: Timeline,
            children:
            {

            },
        },
        {
            name: "Search",
            url: "/search",
            activeIcon: search,
            icon: searchOutline,
            component: Timeline
        },
        {
            name: "Notifications",
            url: "/notifications",
            activeIcon: notifications,
            icon: notificationsOutline,
            component: Timeline
        },
        {
            name: "Profile",
            url: "/profile",
            activeIcon: person,
            icon: personOutline,
            component: Timeline            
        },
        {
            name: "Settings",
            url: "/settings",
            activeIcon: settings,
            icon: settingsOutline,
            component: Settings            
        }
    ];

    const [activeTab, setActiveTab] = useState(tabs[0].name);

    return (
        <>
            <IonTabs onIonTabsDidChange={e => setActiveTab(e.detail.tab)}>
                <IonRouterOutlet >
                    {tabs.map((tab, index) => {
                        return (
                            <Route key={index} exact path={tab.url} component={tab.component}>
                            </Route>
                        );
                    })}
                    <Route exact path='/createpost'>
                        <Timeline></Timeline>
                    </Route>
                    <Route exact path='/onboarding'>
                        <Onboarding/>
                    </Route>
                    <Route exact path='/changeUsername'>
                        <ChangeUsername/>
                    </Route>
                    <Route exact path='/feedback'>
                        <Feedback/>
                    </Route>
                    <Route exact path='/contact'>
                        <Contact/>
                    </Route>
                    <Redirect exact from="/" to="/home" />

                    <Route path="/post/:id">
                    <ViewPost />
                    </Route>
                </IonRouterOutlet>
                <IonTabBar slot="bottom" className={`${styles['transparent']}`} class="ion-no-border" translucent={true} >
                    {tabs.map((tab, barIndex) => {
                        const active = tab.name === activeTab;
                        return (
                            <IonTabButton className={`${styles['tabbutton']}`} key={`tab_${barIndex}`} tab={tab.name} href={tab.url}>
                                <IonIcon className="text-gray-900" icon={active ? tab.activeIcon : tab.icon} />
                            </IonTabButton>
                        );
                    })}
                </IonTabBar>
            </IonTabs></>
    );
};


export default LoggedIn;