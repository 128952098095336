import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonProgressBar, IonRouterLink, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import AuthProvider from '../../../AuthProvider/AuthProvider';
import React from 'react';
import styles from './SignIn.module.css'; // Import css modules stylesheet as styles
import { eye, eyeOff, informationCircle } from 'ionicons/icons';



function SignIn() {
  const { registerValues } = React.useContext(AuthProvider); 
  const [email, setEmail] = useState<string>(!registerValues.registered ? "" : registerValues.email);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [attempts, setAttempts] = useState(0);
  const [present, dismiss] = useIonToast();
  const [passwordfocused, setPasswordFocused] = useState<boolean>(false);

  const { login } = React.useContext(AuthProvider);

  const { doesUsernameExist, authValues } = React.useContext(AuthProvider);
  const history = useHistory();

function togglePassword(){
  setShowPassword(!showPassword);
}

  // handle button click of login form
  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    if(attempts >= 6){
      setLoading(false);
      present({
        keyboardClose: true,
        translucent: true,
        duration:1000,
        icon:informationCircle,
        cssClass: styles.toasts,
        buttons: [{ text: 'OK', handler: () => dismiss(), cssClass: styles.toasts }],
        message: "Too many attempts, try later.",
      })
      return;
    }
    if(email && password){
      let result = login({ user: email, password: password });
      result.then((loginSuccess:boolean) => {
        if(loginSuccess){
          let username = doesUsernameExist();
          username.then((exists:boolean) => {          
          if(exists){
            console.log("Username exists");
            setLoading(false);
            history.push("/home");
          } else {
            
            console.log("Username does not exist");
            setLoading(false);
            history.push("/onboarding");
          }
          })
        } else {
          setLoading(false);
          setAttempts(attempts+1);
            present({
              keyboardClose: true,
              translucent: true,
              duration:1000,
              icon:informationCircle,
              cssClass: styles.toasts,
              buttons: [{ text: 'OK', handler: () => dismiss(), cssClass: styles.toasts }],
              message: "Something went wrong. Please try again.",
            })
        }
      })
      } else {
        setLoading(false);
      }
  }

  return (
    <IonPage className={`${styles.bg}`} >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="" color="white"/>
          </IonButtons>
          <IonTitle color="white">Sign In</IonTitle>
        </IonToolbar>
     
      </IonHeader>
      <IonContent fullscreen>
        {loading ? <IonProgressBar type="indeterminate" className="bg-orange-600"></IonProgressBar>
 : <IonProgressBar type={loading ? "indeterminate" : "determinate"} className="invisible"></IonProgressBar>}   
        <IonList className={`${styles.transparentbg}`}>
          <IonListHeader lines="full" >
            <IonLabel class="ion-text-center" color="white">
            Welcome back!

            </IonLabel>
          </IonListHeader>
          <div className="flex flex-col">
          <div className='mb-8 text-center text-white'>
              Come on in, the waters fine!
            </div>            
            <form id="login" className="flex flex-col"  onSubmit={(e) => {
                              handleLogin(e)
                            }}>
<div className="ion-padding">
<IonItem>
          <IonLabel position='floating'>Email</IonLabel>
          <IonInput autocomplete="email" clearInput={true} inputmode="email" enterkeyhint="next"  type="email" value={email} onIonChange={e => setEmail(e.detail.value!)} required
></IonInput>
          </IonItem>
          <IonItem className="items-end">
            <IonLabel position="floating">Password</IonLabel>
            <IonInput onIonFocus={()=>setPasswordFocused(true)} onIonBlur={()=>setPasswordFocused(false)} id="passwordEnter" class="passwordEnter" autocomplete="current-password" clearOnEdit={true} inputmode="text" enterkeyhint="go" type={showPassword ? 'text': 'password'} value={password} onIonChange={e => setPassword(e.detail.value!)} required
></IonInput>
{passwordfocused ? 
<IonIcon slot="end" className="cursor-pointer transition select-none animate__animated animate__fadeIn focus:select-none hover:text-gray-200 focus:text-gray-600 focus:text-opacity-50 text-white" onClick={()=>togglePassword()} icon={showPassword ? eyeOff : eye}/>
: <IonIcon slot="end" className="cursor-pointer opacity-0 transition" icon={showPassword ? eyeOff : eye}/>
}
          </IonItem>
</div>
          <input type="submit" className={`${styles['submit-enter']}`} />
            <IonButton type="submit" size="large"
            className="flex-none  mt-36 font-bold transition select-none ion-margin-top focus:select-none hover:text-gray-100 focus:text-gray-300 focus:text-opacity-50" color={loading ? "pprimary":"white"} fill="clear">     
Continue
</IonButton>
</form>

            <IonRouterLink routerLink='/recovery' routerDirection='forward'><div className="mt-8 text-lg text-center text-white">Forgot your pasword?</div>
            </IonRouterLink>
          </div>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;