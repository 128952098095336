import { useState } from 'react';
import { getPosts, iPost } from '../../../data/posts';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { useParams } from 'react-router';
import './ViewPost.css';

function ViewPost() {
  const [Post, setPost] = useState<iPost>();
  const params = useParams<{ id: string }>();

  useIonViewWillEnter(() => {
    const getData = async () => {
         const response = await fetch(`https://nightoff.org/tempapi/posts?id=${params.id}`);
         const newData = await response.json();
         setPost(newData);
   };
   getData();
  });


  
  return (
    <IonPage id="view-post-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Timeline" defaultHref="/timeline"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {Post ? ( 
          <>
            <IonItem>
              <IonIcon icon={personCircle} slot="start" color="primary" className='ion-padding-start avatar'></IonIcon>
              <IonLabel className="ion-text-wrap ">
                <h2>
                  {Post.PostedBy}
                  <span className="date">
                    <IonNote>{Post.PostedOn}</IonNote>
                  </span>
                </h2>
                <p>
                {Post.PostedBy}
                </p>
        <h3>
        {Post.PostBody}
        </h3>                
              </IonLabel>
        
            </IonItem>
          </>
        ) : (
          <div>Post not found</div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default ViewPost;
