import { Redirect, Route } from 'react-router-dom';
import { createAnimation, IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import AuthProvider from './AuthProvider/AuthProvider'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React, { useEffect, useState } from 'react';
import { getToken } from './utils/Common';
import LoggedIn from './routes/LoggedIn';
import NotLoggedIn from './routes/NotLoggedIn';


export const animationBuilder = (
  baseEl: Element | Node | Element[] | Node[] | NodeList,
  opts: {
    enteringEl: Element | Node | Element[] | Node[] | NodeList
    leavingEl: Element | Node | Element[] | Node[] | NodeList
  },
) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo('opacity', 0, 1)
    .duration(250)

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo('opacity', 1, 0)
    .duration(250)

  const animation = createAnimation().addAnimation(enteringAnimation).addAnimation(leavingAnimation)

  return animation
}

export function pageTransition(
  opts: {
    enteringEl: Element | Node | Element[] | Node[] | NodeList | HTMLIonContentElement
    leavingEl: Element | Node | Element[] | Node[] | NodeList | HTMLIonContentElement
    direction: string
  },
) {
  const DURATION = 300

  const rootTransition = createAnimation().duration(DURATION).easing('cubic-bezier(0.3,0,0.66,1)')

  const enteringPage = createAnimation().addElement(opts.enteringEl).beforeClearStyles(['display'])

  const leavingPage = createAnimation().addElement(opts.leavingEl).beforeStyles({ display: 'none' })

  if (opts.direction === 'forward') {
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)')
    leavingPage.fromTo('opacity', '1', '0.25')
  } else {
    leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)')
    enteringPage.fromTo('opacity', '0.25', '1')
  }

  rootTransition.addAnimation(enteringPage)
  rootTransition.addAnimation(leavingPage)
  return rootTransition
}

setupIonicReact();

const App: React.FC = () => {
const { authValues } = React.useContext(AuthProvider);

const { verify } = React.useContext(AuthProvider);
const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }    
    let result = verify;
    if (result) {
      setAuthLoading(false);
      console.log("account verified");
    } else {
      setAuthLoading(false);
      console.log("account unverified");
    }
  }, [verify]);

  return(
  <IonApp>
    <IonReactRouter>
    <Route
          path="/"
          render={(props) => {
            return !authValues.authenticated && !getToken() ? <NotLoggedIn {...props} /> : <LoggedIn {...props} />;
          }}
        />
    </IonReactRouter>
  </IonApp>);
};

export default App;
